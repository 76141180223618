<template>
    <el-dialog
        title="用户注册"
        :visible.sync="registerDialogFormVisible"
        width="450px"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closeRegisterDialog('registerDialogForm')"
    >
        <el-form
            :model="register"
            :rules="rules"
            ref="registerDialogForm"
            label-width="100px"
        >
            <el-form-item label="用户名" prop="username">
                <el-input v-model="register.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input v-model="register.password" show-password autocomplete="new-password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmpassword">
                <el-input v-model="register.confirmpassword" show-password autocomplete="new-password"></el-input>
            </el-form-item>
            <el-form-item label="昵称" prop="name">
                <el-input v-model="register.name"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="gender">
                <el-radio-group v-model="register.gender" :fill="gender_color">
                    <el-radio-button :label="1">男</el-radio-button>
                    <el-radio-button :label="2">女</el-radio-button>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button type="success" plain @click="submitRegisterDialog('registerDialogForm')">提 交</el-button>
            <el-button plain @click="closeRegisterDialog('registerDialogForm')">关 闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { UserRegisterRequest } from '@/network/user.js'

export default {
    name: 'registerDialogForm',
    data() {
        return {
            register: {
                username: '',
                password: '',
                confirmpassword: '',
                name: '',
                gender: ''
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur',
                    },
                ],
                confirmpassword: [
                    {
                        required: true,
                        message: '请再次确认密码',
                        trigger: 'blur',
                    },
                ],
                name: [
                    {
                        required: true,
                        message: '请输入昵称',
                        trigger: 'blur',
                    },
                ],
                gender: [
                    {
                        required: true,
                        message: '请选择性别',
                        trigger: 'blur',
                    },
                ]
            },
        }
    },
    computed: {
        gender_color() {
            if(this.register.gender === 1) {
                return '#2894FF'
            } else if (this.register.gender === 2) {
                return '#FF95CA'
            }
        }
    },
    methods: {
        closeRegisterDialog(formName) {
            this.$emit('exitRegisterDialog')
            this.$refs[formName].resetFields()
        },
        submitRegisterDialog(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                if (this.register.password !== this.register.confirmpassword) {
                    this.$message.error('两次新密码不一致')
                    return
                }

                UserRegisterRequest(this.register)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('您已提交注册申请，请联系管理员进行审核。')
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => this.closeRegisterDialog(formName))
            })
        },
    },
    filters: {},
    props: {
        registerDialogFormVisible: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch:{}
}
</script>

<style lang='less'></style>