<template>
    <div class="login_container">
        <div class="login_box">
            <div class="avatar_box">
                <img
                    src="../../assets/img/login_avatar.gif"
                    class="avatar_img"
                />
            </div>
            <el-form
                class="login_form"
                ref="user"
                :model="user"
                :rules="verification"
            >
                <div style="font-family: HarmonyOS_Sans_Black;font-size: 30px;text-align: center;margin-bottom: 5px;"><b>風聲·黑名單</b></div>
                <div style="font-family: HarmonyOS_Sans_Light;font-size: 12px;text-align: center;margin-bottom: 5px;">资料库</div>
                <el-form-item prop="username">
                    <el-input
                        placeholder="请输入用户名"
                        v-model="user.username"
                        prefix-icon="el-icon-user"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input
                        placeholder="请输入密码"
                        @keyup.enter.native="onLogin"
                        v-model="user.password"
                        prefix-icon="el-icon-lock"
                        show-password
                    ></el-input>
                </el-form-item>
                <el-form-item class="btn_iteam">
                    <el-button
                        class="btn_login"
                        type="success"
                        plain
                        v-loading.fullscreen.lock="login_loading"
                        @click="validate_login('user')"
                        >登录</el-button
                    >
                    <el-button
                        class="btn_login"
                        type="primary"
                        plain
                        @click="registerDialogFormVisible = true"
                        >注册</el-button
                    >
                </el-form-item>
                <register-dialog
                    :registerDialogFormVisible="registerDialogFormVisible"
                    @exitRegisterDialog="registerDialogFormVisible = false"
                ></register-dialog>
            </el-form>
        </div>
        <div class="beian">
            <el-link type="primary" href="https://beian.miit.gov.cn/" target="_blank"><b>鄂ICP备2023022699号-1</b></el-link>
        </div>
    </div>
</template>

<script>

import { UserLoginRequest } from '@/network/user.js'
import '@/assets/style/login/login_view.css'
import RegisterDialog from '@/views/login/registerDialog.vue'

export default {
    name: 'LoginView',
    data() {
        return {
            login_loading: false,
            user: {
                username: '',
                password: '',
            },
            verification: {
                username: [
                    {
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '请输密码',
                        trigger: 'blur',
                    }
                ],
            },
            registerDialogFormVisible: false
        }
    },
    computed: {},
    methods: {
        validate_login(ref) {
            this.$refs[ref].validate((valid) => {
                if (!valid) {
                    return
                }
                this.onLogin()
            })
        },
        onLogin() {
            this.login_loading = true
            UserLoginRequest(this.user)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('登录成功')
                        this.$router.replace('/')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        RegisterDialog
    }
}
</script>